.dropdown-container {
    margin: 20px;
  }
  
  /* Custom styling for the dropdown */
  .custom-dropdown {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }
  
  /* Style selected option */
  .custom-dropdown option:checked {
    background-color: #007bff;
    color: #fff;
  }
  
  /* Optional: Style the label */
  label {
    margin-right: 10px;
  }