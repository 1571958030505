

.screen-one{
    position:absolute;
    top: 18%;
    left: 22%;
    width: 350px;
    z-index: 9999999;
}

.screen-two{
    position:absolute;
    top: 46%;
    left: 10%;
    width: 350px;
}

.screen-three{
    position:absolute;
    top: 46%;
    left: 35%;
    width: 350px;
}

.ad-one{
    position:absolute;
    top: 21.8%;
    left: 24%;
    width: 288px;
    z-index: 9999999;
    transition: opacity 2s ease-out;
}

.ad-two{
    position:absolute;
    top: 49.7%;
    left: 12%;
    width: 288px;
}

.ad-three{
    position:absolute;
    top: 49.7%;
    left: 37%;
    width: 288px;
}

.text-wrapper {
    height: 20%;
    width: 70%;
    text-align: center;
    height: 46%;
}

.heading{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 2.8vw;
    letter-spacing: .8px;
    text-align: left;
    margin-bottom: 30px;
}

.discriptions{
    font-family: 'Open Sans', sans-serif;
    font-weight: 500;
    color: #fff;
    font-size: 1vw;
    text-align: left;
    line-height: 24px;
    margin: 0px;
    width: 80%;
}