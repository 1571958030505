.schedule{
    position: absolute;
    border-radius: 4px;
    height: 38px;
    width: 138px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 10px;
    opacity: .5;
    color: #060609;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
}




.calander-container {
    display: grid;
    /* grid-template-rows: repeat(4, 60px); */
    grid-template-columns: repeat(7, 1fr);
    width: 990px;
    height: 500px;
    /* background-color: aqua; */
    position: relative;
}

.item {
    background-color: #FEFEFE;
    border-radius: 4px;
    height: 38px;
    width: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.item:hover {
    background-color: #9698d6;
    color: #FEFEFE;
    cursor: pointer;
}

.days-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 990px;
    height: 32px;
}

.days {
    background-color: #FEFEFE;
    border-radius: 4px;
    height: 26px;
    width: 138px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #060609;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.time-container {
    margin-top: 70px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 70px;
    height: 500px;
}

.time {
    background-color: #FEFEFE;
    border-radius: 4px;
    height: 38px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: #060609;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

