#customers {
    color: #000;
    border-collapse: collapse;
    width: 100%;
    font-weight: 500;

  }
  
  #customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  #customers tr:nth-child(even){background-color: #f2f2f2;}
  
  /* #customers tr:hover {background-color: #ddd;} */
  
  #customers td{
    vertical-align: top;
  }

  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #5e81f4;
    color: white;
  }

  .admin-wrapper{
    padding: 30px;
    height: 90vh;
    overflow: scroll;
  }

  .admin-button {
    border: 1px solid #5e81f4;
    color: #5e81f4;
    margin: 6px;
    margin-left: 0px;
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    }

    .admin-button-active{
    cursor: pointer;
      border: 1px solid #5e81f4;
      margin: 6px;
      margin-left: 0px;
      padding: 8px 10px;
      border-radius: 4px;
      background-color: #5e81f4;
      color: #f2f2f2;
    }

    .admin-button:hover{
      background-color: #5e81f4;
      color: #f2f2f2;
    }



 

  .screen-count-title{
    font-weight: 500;
    margin: 0px;
    color: #000;
    margin-bottom: 14px;
  }

  .screen-count-title-span{
    margin-left: 200px
  }

  .screen-info-container{
    display: flex;
    border-bottom: 1px solid  #ddd;
  }

  .screen-info-container-margin{
    display: flex;
    margin-top: 12px;
  }

  .screen-count {
    margin: 0px;
    color: #9393bf;
    font-weight: 400;
    padding-left: 4px;
  }

  .screen-name{
    width: 280px;
    font-weight: 400;
    padding-left: 4px;
  }


  .screen-count-title {
    margin: 0px;
    margin-bottom: 10px;
  }

  .screen-name-title {
    width: 280px;
    margin-bottom: 10px;
  }

  .pdf-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vw;
    height: 88vh;
    z-index:99;

  }
  .pdf-bg{
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E2D4D;
    opacity: .6;
    z-index:10;
  }