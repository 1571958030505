

.login-heading{
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    color: #fff;
    font-size: 28px;
    letter-spacing: .8px;
    text-align: center;
}

.loader-small {
    border: 1px solid #5e81f4;
    border-radius: 50%;
    border-top: 2px solid #FEFEFE;
    width: 12px;
    height: 12px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-left: 6px;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
